import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import styles from '../constants/theme'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Text from '../components/Text'
import Hero from '../components/Hero'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'
import StyledLink from '../components/StyledLink'
import GatsbyImage from 'gatsby-image'

export const pageQuery = graphql`
  query($id: String!) {
    pdPage: wordpressPage(id: { eq: $id }) {
      title
      acf {
        heading
        link_button_new_page {
          __typename
          ... on WordPressAcf_youtube {
            text
            url
          }
          __typename
          ... on WordPressAcf_pdf_file {
            text
          }
        }
        speakers {
          name
          description
          image {
            localFile {
              childImageSharp {
                fixed(height: 220, toFormat: JPG, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        agenda
      }
    }
  }
`

export default function PD2017({ data }) {
  const { acf } = data.pdPage
  return (
    <Layout>
      <SEO title={data.pdPage.title} keywords={['']} />
      <ThemeProvider theme={styles.palette.blue}>
        <Hero>
          <div className="container text-center">
            <Text h1>{data.pdPage.title}</Text>
          </div>
        </Hero>
      </ThemeProvider>
      <MainContentWrap>
        <CustomContainer>
          <ContentSection>
            <div
              dangerouslySetInnerHTML={{ __html: acf.heading }}
              css={css`
                margin-bottom: ${styles.margin.mid};
              `}
            />
          </ContentSection>
          {acf.link_button_new_page && (
            <ContentSection>
              {acf.link_button_new_page.map((link, i) => {
                // Directly render the default PDF link if it's a PDF type
                if (link.__typename === 'WordPressAcf_pdf_file') {
                  return (
                    <StyledLink
                      block
                      to="/src/images/OCMA-Constitution-May-2024.pdf" // Default PDF link
                      key={`pd_link_${i}`}
                    >
                      {link.text}
                    </StyledLink>
                  )
                }
                // Handle YouTube links or other types normally
                return (
                  <StyledLink block to={link.url} key={`pd_link_${i}`}>
                    {link.text}
                  </StyledLink>
                )
              })}
            </ContentSection>
          )}
          {acf.speakers && (
            <ContentSection>
              <div className="row">
                {acf.speakers.map((speaker, i) => (
                  <div className="col-md-4 text-center" key={`pd_speaker_${i}`}>
                    <GatsbyImage
                      fixed={speaker.image.localFile.childImageSharp.fixed}
                    />
                    <Text>{speaker.name}</Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: speaker.description }}
                      css={css`
                        & i {
                          font-weight: bold;
                        }
                      `}
                    />
                  </div>
                ))}
              </div>
            </ContentSection>
          )}
          {acf.agenda && (
            <ContentSection>
              <div dangerouslySetInnerHTML={{ __html: acf.agenda }} />
            </ContentSection>
          )}
        </CustomContainer>
      </MainContentWrap>
    </Layout>
  )
}
